export const main = {
  onboardingTitle: 'We Bring The Best Car for You as an Enthusiast',
  onboardingDescription:
    'Get experience riding your dream car, we will set up the car, you just need to book and go rock with the car',
  onboardingButton: 'Get started',
  signinTitle: 'Login',
  requiredError: 'This field is required',
  emailLabel: 'Email',
  emailPlaceholder: 'Enter your email',
  emailValidationError: 'Invalid Email',
  firstNameLabel: 'First Name',
  firstNamePlaceholder: 'Enter your first name',
  lastNameLabel: 'Last Name',
  lastNamePlaceholder: 'Enter your last name',
  phoneLabel: 'Phone Number',
  phonePlaceholder: 'Enter your phone number',
  phoneValidationError: 'Phone number is to short',
  password: 'Password',
  passwordPlaceholder: 'Enter your password',
  passwordValidationError: 'Password is to short',
  confirmPassword: 'Confirm password',
  confirmPasswordPlaceholder: 'Confirm password',
  confirmPasswordValidationError: 'Passwords does not match',
  confirmNewPasswordValidationError: 'New passwords does not match',
  currentPasswordValidationError: 'Current password is incorrect',
  signinRememberMe: 'Remember me',
  signinForgotPassword: 'Forgot password?',
  signin: 'Sign In',
  socialMediaTitle: 'Or continue with',
  signUpLabel: "Don't have an account?",
  signUp: 'Sign Up',
  signUpTermAndConditions: 'Term and Conditions',
  signUpButton: 'Sign Up',
  signInLabel: 'Already have an account?',
  referCodeTitle: 'Refer Code',
  referCodePlaceholder: 'Enter refer code',
  doesntHaveCode: "Doesn't have code?",
  skip: 'Skip',
  referCodeConfirm: 'Confirm Code',
  otpTitle: 'Enter OTP',
  notReceivedCode: 'Not received code? ',
  resend: 'Resend',
  otpConfirm: 'Confirm',
  continue: 'Continue',
  congratulations: 'Congratulations!',
  accountCreatedSuccesfully: 'Your account has be created succesfully',
  homeTitle: 'Our Services',
  carTowing: 'Car Towing',
  carTowingSubtitle: 'Towing in compliance with the law',
  parkingTicket: 'Parking Ticket',
  parkingTicketSubtitle: 'Report unauthorized parking',
  companyName: 'Company Name',
  companyNamePlaceholder: 'If private, release',
  companyStreet: 'Company street',
  companyStreetPlaceholder: 'Enter company street',
  companyStreetNumber: 'Company street number',
  companyStreetNumberPlaceholder: 'Enter company street number',
  companyZipCode: 'Company zip code',
  companyZipCodePlaceholder: 'Enter company zip code',
  companyCity: 'Company city',
  companyCityPlaceholder: 'Enter company city',
  companyAddress: 'Address',
  companyAddressPlaceholder: 'Enter address',
  userRoles: 'User Roles',
  switchToUserProfile: 'Switch To User Profile',
  preference: 'Preference',
  darkMode: 'Dark Mode',
  general: 'General',
  rewards: 'Rewards',
  referAFriend: 'Refer A Friend',
  becomeADriver: 'Become A Driver',
  defaultPayment: 'Default Payment',
  addNewLicenses: 'Add a plate',
  addNewProperty: 'Add a parking',
  addPropertyButton: 'Add a parking',
  licenseTitle: 'Manage license plates',
  parkingNotExist: 'You must first add parking',
  contact: 'Contact',
  FAQ: 'FAQ',
  aboutUs: 'About Us',
  helpSupport: 'Help & Support',
  termsCondition: 'Terms & Condition',
  dataProtection: 'Data Protection',
  dataProtectionTowShare: 'TowShare Group GmbH Datenschutzerklärung',
  imprint: 'Imprint',
  logout: 'Logout',
  editProfile: 'Edit Profile',
  done: 'Done',
  changeProfilePhoto: 'Change profile photo',
  referalCode: 'Referal code',
  redeemDetails: 'Redeem Details',
  add: 'Add',
  bankTransfer: 'Bank Transfer',
  newBankDetails: 'New Bank Details',
  paypal: 'PayPal',
  newPaypalDetails: 'New PayPal Details',
  bankDetails: 'Bank Details',
  save: 'Save',
  beneficaryName: 'Beneficiary Name',
  beneficaryNamePlaceholder: 'Enter Beneficiary Name',
  bankName: 'Bank Name',
  bankNamePlaceholder: 'Enter Bank Name',
  accountNumber: 'Account Number',
  accountNumberPlaceholder: 'Enter Account Number',
  iban: 'IBAN',
  ibanPlaceholder: 'Enter IBAN',
  street: 'Street',
  streetPlaceholder: 'Enter Street',
  streetNumber: 'Number',
  streetNumberPlaceholder: 'Enter Number',
  zipCode: 'ZIP code',
  zipCodePlaceholder: 'Enter ZIP code',
  city: 'City',
  cityPlaceholder: 'Enter city',
  successRedeemSubtitle: 'Successfully added Bank Account',
  successContractSubtitle: 'Your parking attribute has been added',
  rewardsHistory: 'Rewards History',
  history: 'History',
  sortBy: 'Sort By',
  dateOfBirth: 'Date Of Birth',
  dateOfBirthPlaceholder: 'Enter your date of birth',
  license: 'License',
  govermentId: 'Goverment ID',
  requestForVerification: 'Request For Verification',
  verificationRequested: 'Verification Requested',
  successDriverSubtitle:
    'We have receved your request to become a Falsch Parker driver. Your application status will be notified to you within 1-2 working days.',
  orders: 'Orders',
  badParkedCar: 'Illegally Parked Car',
  badParkedCarConfirmationPickup: 'Illegally Parked Car',
  badParkedCarTicketConfirmationPickup: 'Illegally Parked Car',
  licensePlate: 'License Plate',
  licensePlatePlaceholder: 'Enter license plate',
  carModelName: 'Car Model Name',
  carModelNamePlaceholder: 'Enter car model name',
  carPhoto: 'Car Photo',
  addPhoto: 'Add Photo',
  addVideo: 'Add Video',
  yourLocation: 'Your Location',
  findDriver: 'Find Driver',
  changeLocation: 'Change Location',
  cancel: 'Cancel',
  cancelSecond: 'Cancel',
  findingTheDriver: 'Finding the driver',
  cancelFindDriverAlert: 'Are you sure want to cancel the order?',
  cancelOrderAlert: 'Are you sure want to cancel the order?',
  confirmOrderAlert: 'Are you sure all data you have been input is correct?',
  reward: 'Reward',
  pickUpLocation: 'Pick up location',
  ETA: 'Estimated arrival time',
  towingDriverName: 'Towing driver name',
  addressNotFound: 'The address was not found. Please try again',
  successOrderSubtitle: 'Your order has been successfully placed',
  loginError: 'Incorrect email or password',
  standardPayment: 'Standard payment',
  addParking: 'Add Parking',
  unsuccessReportTitle: 'Dear Customer,',
  unsuccessReportSubtitle:
    'Please add your parking property to start the towing report or ticket report',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  changePassword: 'Change Password',
  confirmChangeSave: 'The changes have been saved',
  orderDetails: 'Order Details',
  carModel: 'Car Model',
  dateAndTime: 'Date and Time',
  driveInformation: 'Driver Information',
  customerInformation: 'Customer Information',
  ticket: 'Ticket',
  orderID: 'Order ID',
  companyExecutive: 'Company Executive',
  confirmTicket: 'The turnover compensation was successfully created',
  confirmTowingStatus: 'Will pickup your order',
  all: 'All',
  new: 'New',
  accepted: 'Accepted',
  inProgress: 'In Progress',
  towedAway: 'Towed Away',
  finished: 'Finished',
  finish: 'Finish',
  cancelled: 'Cancelled',
  issued: 'Issued',
  userNoReportsYet: 'There are no reports created by you yet',
  driverNoReportsYet: 'There are no report assigned to you yet',
  propertyAndLicenses: 'Property & Licenses',
  addProperty: 'Add Property',
  newPropertyDetails: 'New Property',
  addPropertyDetails: 'Add Property Details',
  addLicenses: 'Add Licenses',
  newLicenseDetails: 'New License Details',
  addLicenseDetails: 'Add License Details',
  carModelPlaceholder: 'Enter Car Model',
  propertyName: 'Property Name',
  propertyNamePlaceholder: 'Enter Property Name',
  address: 'Address',
  addressPlaceholder: 'Enter Address',
  signAContract: 'Sign a contract',
  contract: 'Contract',
  contractName: 'Session Contract',
  contractSubHeading:
    'The app and our services are always free for the client.',
  from: 'Date',
  inBetween: 'between',
  client: 'Client',
  contractor: 'Contractor',
  contractorAddress: 'TowShare Group GmbH\nSchützengasse 1\n8001 Zurich',
  contractorShortAddress: 'TowShare Group GmbH',
  and: 'and',
  contractSubtitle:
    '<strong>Contract Subject:</strong> Through the WrongParking app, users can impose a handling fee (fine) on offenders or arrange for their vehicles to be towed.',
  contractFirstPointTitle: 'Contract Subject',
  contractFirstPointContent:
    'The contractor handles the processing and sending of handling fees for parking violations and conducts the towing of offenders.',
  contractSecondPointTitle: 'Handling Fee',
  contractSecondPointContent:
    'A handling fee is imposed on the wrong parker for parking violations.',
  contractThirdPointTitle: 'Services of the Contractor',
  contractThirdPointContent:
    'At the client’s request, the contractor tows illegally parked vehicles, including towing, safe transport, and proper storage of the vehicles.',
  contractThirdPointBottomContent:
    'Liability: The contractor is not liable for damages unless they result from gross negligence or intent.',
  contractFourthPointTitle: 'Assignment',
  contractFourthPointContent:
    'The assignment is made through the WrongParking app or, in special cases, by phone.',
  contractFifthPointTitle: 'Duty of Care',
  contractFifthPointContent:
    'The contractor is committed to providing services diligently and professionally according to current standards and is responsible for the conduct of its employees.',
  contractSixthPointTitle: 'Assignment of Claims',
  contractSixthPointContent:
    'The client assigns all claims against wrong parkers to the contractor, including the rights to enforce handling fees and towing orders. For repeated violations, the contractor is entitled to file a report according to Art. 258 ZPO.',
  contractSeventhPointTitle: 'Truthful Information Regarding the Parking Space',
  contractSeventhPointContent:
    'The client confirms that a valid rental contract exists for the parking space or that the parking space is owned by them.',
  contractEighthPointTitle: 'Addendum for Gas Stations',
  contractEighthPointContent:
    'With the WrongParking app, gas station operators can report cases of fuel theft and drive-offs. The contractor handles collections and transfers the outstanding fuel amount to the gas station operator the day after successful payment. A handling fee of at least 20.00 CHF is imposed on the offending driver.',
  contractNinthPointTitle: 'Confidentiality',
  contractNinthPointContent:
    'Both parties commit to the confidentiality of all information obtained within the framework of the contract.',
  contractTenthPointTitle: 'Term and Termination',
  contractTenthPointContent:
    'The contract takes effect upon signing and is valid for an indefinite period. Termination is possible at any time in writing.',
  contractEleventhPointTitle: 'Changes',
  contractEleventhPointContent:
    'Changes to the contract require the written consent of both parties.',
  contract12PointTitle: 'Applicable Law and Jurisdiction',
  contract12PointContent:
    'The contract is governed by Swiss law, with jurisdiction in Zurich.',
  schlieren: 'Schlieren',
  zurich: 'Zurich',
  refCodeNotFound: 'Ref Code not found',
  notifications: 'Notifcations',
  contractSignature: 'Contract Signature',
  inputSignature: 'Input Signature',
  signatureInfo:
    'To continue the process you need to collect the user signature',
  signature: 'Signature',
  termAndConditions: 'Terms and conditions',
  orderNumber: 'Order Number',
  confirmOrderArrival: 'Are you sure you have arrived to the destination?',
  confirmCarPicked: 'Are you sure you have arrived at the right car?',
  confirmCarParkArrival:
    'Are you sure you have arrived at the car park destination?',
  cancelCarParkArrival: 'Are you sure you want to cancel the order?',
  uploadVideoAndPhotos: 'Upload Video & Photos',
  uploadVideoAndPhotosInfo:
    'To verify, please upload a video of the car followed by 3 pictures of it',
  carVideo: 'Car Video',
  carkParkLocation: 'Car Park Location',
  carFalschOffice: 'Car Falsch Office',
  successDriverOrderSubtitle: 'You have completed the order',
  registerPlateCheck: 'Register Plate Check',
  registerPlateCheckSubtitle: 'Check if the car is allowed to park on address',
  parkedCarRegisterPlateCheck: 'Parked Car Register Plate Check',
  formPopupValidation: 'Are you sure all data you have been input is correct?',
  registerPlate: 'Register  plate',
  isAllowedToParking: 'is allowed to parking',
  isNotAllowedToParking: 'is not allowed to parking',
  startTicket: 'Start Ticket',
  startTowing: 'Start Towing',
  successLicenseSubtitle: 'Your license attribute has been added',
  editLicense: 'Edit License',
  editProperty: 'Edit Property',
  changeSave: 'The changes have been saved',
  deleteLicense: 'Delete License',
  deleteLicenceConfirm: 'Are you sure you want to remove the license?',
  switchToDriverProfile: 'Switch to Driver Profile',
  superAdmin: 'Super Admin',
  user: 'User',
  driver: 'Driver',
  customerName: 'Customer Name',
  customerNumber: 'Customer Number',
  orderPlacement: 'Order Placement',
  ticketOrderPlacement: 'Order Placement',
  orderAcceptence: 'Order Acceptence',
  arrivalTime: 'Arrival Time',
  timeOfPickup: 'Time of Pickup',
  timeOfExecution: 'Time of Execution',
  verifyCarOwner: 'Verify Car Owner',
  fullName: 'Full Name',
  fullNamePlaceholder: 'Enter full name',
  emailAddress: 'E-mail',
  contactDetails: 'Contact Details',
  fullAddress: 'Full Address',
  driverId: 'Driver ID',
  carRegistration: 'Car registration',
  recent: 'Recent',
  older: 'Older',
  licenseFront: 'License Front',
  licenseBack: 'License Back',
  govermentIdFront: 'Goverment ID Front',
  govermentIdBack: 'Goverment ID Back',
  helpAndSupport: 'Help and support',
  falschParkerHelp: 'Falsh Parker Help',
  helpContent:
    'You can reach support around the clock by phone or WhatsApp. We answer questions about how the app works or can take specific action directly',
  allowedCarPopup: 'This car is allowed to park and cannot be towed.',
  back: 'Back',
  call: 'Call',
  message: 'Message',
  confirm: 'confirm',
  confirmShort: 'confirm',
  confirmNext: 'confirm',
  driverName: 'driver name',
  delete: 'delete',
  retake: 'retake',
  language: 'Language',
  defaultLanguage: 'Default Language',
  de: 'German',
  en: 'English',
  it: 'Italiano',
  fr: 'Français',
  oldPasswordPlaceholder: 'Enter old password',
  newPasswordPlaceholder: 'Enter new password',
  destinationArrived: 'Destination Arrived',
  userName: 'User Name',
  downloadReceipt: 'Download Receipt',
  imptint: 'Imptint',
  imptintTitle: 'Our Imprint',
  driverAcceptError: "You can't accept more than one report at the same time",
  referAndPromote: 'Refer & promote',
  referToPromote: 'Refer, to promote falsch-parker.ch',
  orderStatus: 'Order status',
  driverAcceptConfirm: 'Are you sure you want to accept report?',
  driverDectineConfirm: 'Are you sure you want to decline report?',
  backendErrorPartOne: "We've faced some errors. Please contact to ",
  backendErrorPartTwo: 'support',
  noNotification: 'There are no notifications yet',
  closeReportContent: 'Are You sure to cancel the report?',
  executeOrder: 'Execute Order',
  pleaseAddSignature: 'Please, add signature',
  next: 'Next',
  accept: 'Accept',
  decline: 'Decline',
  arrived: 'Parking Arrive',
  successCarOwenrContractSubtitle: 'Thanks for finishing towing report',
  minute: 'minute',
  minutes: 'minutes',
  turnOnGPS: 'Turn on GPS',
  gpsPopupContent:
    "Please turn on your device's GPS. Otherwise, you won't be able to use the app",
  gpsErrorPopupContent:
    'Please allow Your browser to use location services. You can do it in browser settings',
  404: 'Oops! The page you were trying to access cannot be found',
  searchPlaceholder: 'Search plate...',
  resetPasswordTitle: 'Please enter Your account e-mail',
  resetPassword: 'Resend password',
  resetPasswordError: 'Given e-mail is not connected to any account ',
  resetPasswordSuccess:
    'You should receive e-mail with password reset instructions',
  userExist: 'This e-mail is used for other account. Please different one',
  yes: 'Yes',
  no: 'No',
  pinWrongParkedLocation: 'Wrong Parker Location',
  pinTowingTruckLocation: 'Towing Truck Location',
  pinParkingSpaceLocation: 'Parking Space Adress',
  googleMapsPopupContent:
    'Would you like to use Google Maps for route guidance?',
  screenAlwaysOn: 'Please keep the screen always on',
  tooBigFile:
    'The video file has too big size. Please make new, shorter video.',
  uploadVideoAndPhotos360: 'Upload Video & Photos',
  uploadVideoAndPhotos360Subtitle:
    'To verify please upload a video of the car followed by 3 pictures of it ',
  twentyCashback: '20 CHF CASHBACK',
  fiftyCashback: '50 CHF CASHBACK',
  reportNumber: 'Report number',
  tenChf: '10 CHF',
  referAndGet: 'REFER ADN GET',
  towingActiveHistoryTitle: 'Towing reports',
  towingDeactiveHistoryTitle: 'Towing reports',
  ticketHistoryTitle: 'Ticket reports',
  allHistoryTitle: 'All reports History',
  rentalAgreementPhotos: 'Rental agreement photos',
  addRentalProperty: 'Add Rental Parking',
  newRentalPropertyDetails: 'New Rental Parking',
  addRentalPropertyDetails: 'Add Rental Property Details',
  uploadPhotos: 'Upload photos',
  uploadPhotosInfo:
    'For verification, please upload pictures of  the rental property agreement',
  invalidZipCodePopup:
    'Towing service is currently not available in your region. We are working hard to be available in your region soon',
  freeParking: 'Drop Off Parking',
  paymentMethod: 'Payment method',
  invoice: 'Invoice',
  paymentOnTheSpotCash: 'Payment on the spot (Cash)',
  paymentOnTheSpotCard: 'Payment on the spot (Credit Card)',
  inProgressButton: 'In Progress',
  inProgressSubtitle: 'Check in-progress report status',
  policeParkingAddress: 'Destination Parking Address',
  acceptedReportError: 'Another driver already accepted this report',
  clientName: 'Company Name',
  selectParkingArea: "Client's Parkings in Area",
  autoGPSOff:
    'Dear User. Please turn on the GPS on your device. Otherwise you will not be able to use the auto location.',
  paymentStatus: 'Payment Status',
  cashbackStatus: 'Cashback Status',
  open: 'Open',
  paid: 'Paid',
  notPaid: 'Not Paid',
  paidOnTheSpot: 'Paid On The Spot',
  paidOnSpotButton: 'Customer pays in the store',
  pending: 'Pending',
  clients: 'Clients',
  clientNumber: 'Client number',
  contactNumber: 'Contact number',
  clientFullName: 'Client name',
  cashbackOn: 'Cashback on',
  cashbackOff: 'Cashback off',
  clientsSearchPlaceholder: 'Search clients ...',
  wrongAddress: 'Please input the valid address or enable location',
  clientReports: "Client's Finished reports",
  clientReportsTitle: "Client's Finished reports",
  clientReportsSearchPlaceholder: 'Search...',
  downloadContract: "Download User's Contract",
  cashback: 'Cashback',
  claimed: 'Claimed',
  bankAccount: 'Bank Account',
  newBank: 'Add new bank account',
  downloadAppPopup: 'Download the FalschParker App',
  tap: 'Tap',
  tapInstruction: 'then "Add to Home Screen"',
  sign: 'Sign',
  bill: 'Bill',
  gasStationButtonTitle: 'Gas Station Reports',
  gasStationButtonSubtitle: 'Customers who refuel without payment',
  price: 'Price',
  priceGasStation: 'Price',
  billTime: 'Invoice time',
  cancelledTime: 'Cancelled time',
  bankAccountName: 'Account holder',
  bankAccountNameePlaceholder: 'Account owner',
  gasStationOn: 'Gas station on',
  gasStationOff: 'Gas station off',
  confirmReportDelete: 'Are you sure you want to delete the order?',
  deleteReport: 'Delete the order',
  gasStationSelect: 'Select gas station',
  activateParkingTitle:
    'Leave your license plate so that we can register you as a visitor',
  activateParkingPlaceholder: 'Enter license plate',
  confirmActivateParking: 'Save',
  confirmActivatedParking: 'Plate number',
  ticketId: 'Ticket number',
  ticketIdPlaceholder: 'Enter the ticket number',
  notes: 'Notes',
  notesPlaceholder: 'Enter notes',
  userOrderAddress: 'Select location',
  reportType: 'Report type',
  plateValidation: 'Only letters and numbers',
  selectUserType: 'Please select the type of user:',
  companyClient: 'Company client',
  userClient: 'User client',
  pleaseSelectParking: 'Please select a parking zone',
  pleaseChangeAddress:
    'Please enter a different address and select a parking zone',
  appVersion: 'App version',
  confrimPayOnSpot: 'Do you confirm the payment on the spot?',
  selectTheLocation: 'Select the location',
  qrScanner: 'QR scanner',
  fuelFraud: 'Fuel fraud',
  ticketSection: 'Parking fine',
  pricePlaceholder: 'Enter  price',
  cashbackPlaceholder: 'Enter the cashback',
  gasStationSection: 'Fuel fraud',
  towingSection: 'Towing job',
  towingTickets: 'Orders / Parking fines',
  photo: 'Photo',
  billPhoto: 'Bill photo',
  accidentalEvasion: 'Customer was in shop',
  intensionalEvasion: 'Customer was not in shop',
  cameraAlert:
    'Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.',
  loadingMediaPopup: 'Files are being uploaded, please do not close the app',
  objectionRaiseEmailPlaceholder: 'Your E-Mail',
  notePlaceholder: 'Description objection',
  driverCityPlaceholder: 'Enter city',
  priceBill: 'Bill Amount',
  priceBillPlaceholder: 'Price of fuel filled',
  qrCodeId: 'QR-Parkbusse ID',
  cancelButton: 'Cancel',
  cancelSelectParking: 'Cancel',
  sharingError: "Your system doesn't support sharing this file.",
  startScanner: 'Start scanner',
  downloadQrCode: 'Download QR Code',
  generateQrCode: 'Generate QR Code',
  pleaseAddPrice: 'Please, add price',
  emptyRun: 'Empty Run',
  emptyRunCashback: 'Empty run cashback',
};
