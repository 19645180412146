export const main = {
  onboardingTitle: 'Résolvez votre problème de stationnement illicite!',
  onboardingDescription:
    'Avec Falsch-Parker, émettez des compensations et remorquez dans le respect de la loi.',
  onboardingButton: 'Commencez maintenant',
  signinTitle: 'Connexion',
  requiredError: 'Ce champ est requis',
  emailLabel: 'E-mail',
  emailPlaceholder: 'Entrez votre e-mail',
  emailValidationError: 'Votre e-mail est incorrect',
  firstNameLabel: 'Prénom',
  firstNamePlaceholder: 'Entrez votre prénom',
  lastNameLabel: 'Nom',
  lastNamePlaceholder: 'Entrez votre nom',
  phoneLabel: 'Numéro de téléphone',
  phonePlaceholder: 'Entrez votre numéro de téléphone',
  phoneValidationError: 'Numéro de téléphone trop court',
  password: 'Mot de passe',
  passwordPlaceholder: 'Entrez votre mot de passe',
  passwordValidationError: 'Mot de passe trop court',
  confirmPassword: 'Confirmez le mot de passe',
  confirmPasswordPlaceholder: 'Confirmez le mot de passe',
  confirmPasswordValidationError: 'Les mots de passe ne correspondent pas',
  confirmNewPasswordValidationError:
    'Les nouveaux mots de passe ne correspondent pas',
  currentPasswordValidationError: 'Mot de passe actuel incorrect',
  signinRememberMe: 'Se souvenir de moi',
  signinForgotPassword: 'Mot de passe oublié?',
  signin: 'Se connecter',
  socialMediaTitle: 'Ou continuer avec',
  signUpLabel: 'Pas de compte?',
  signUp: "S'inscrire",
  signUpTermAndConditions: 'CGU',
  signUpButton: 'Créer un compte',
  signInLabel: 'Déjà un compte?',
  referCodeTitle: 'Code de parrainage',
  referCodePlaceholder: 'Laissez vide si aucun code',
  doesntHaveCode: 'Déjà un compte?',
  skip: 'Passer',
  referCodeConfirm: 'Confirmer le code',
  otpTitle: 'Entrez OTP',
  notReceivedCode: 'Pas reçu de code?',
  resend: 'Renvoyer',
  otpConfirm: 'Confirmer',
  continue: 'Continuer',
  congratulations: 'Félicitations!',
  accountCreatedSuccesfully: 'Votre compte a été créé avec succès',
  homeTitle: 'Nos services',
  carTowing: 'Remorquage',
  carTowingSubtitle: 'Remorquage dans le respect de la loi',
  parkingTicket: 'Amende de stationnement',
  parkingTicketSubtitle: 'Sanctionner le stationnement illicite',
  companyName: "Nom de l'entreprise",
  companyNamePlaceholder: "Entrez le nom de l'entreprise",
  companyStreet: "Rue de l'entreprise",
  companyStreetPlaceholder: "Entrez la rue de l'entreprise",
  companyStreetNumber: "Numéro de rue de l'entreprise",
  companyStreetNumberPlaceholder: "Entrez le numéro de rue de l'entreprise",
  companyZipCode: "Code postal de l'entreprise",
  companyZipCodePlaceholder: "Entrez le code postal de l'entreprise",
  companyCity: "Ville de l'entreprise",
  companyCityPlaceholder: "Entrez la ville de l'entreprise",
  companyAddress: 'Adresse',
  companyAddressPlaceholder: "Entrez l'adresse",
  userRoles: "Rôles d'utilisateur",
  switchToUserProfile: 'Changer de profil utilisateur',
  preference: 'Préférences',
  darkMode: 'Mode sombre',
  general: 'Gestion du stationnement',
  rewards: 'Récompenses',
  referAFriend: 'Ajouter un employé',
  becomeADriver: 'Devenir conducteur',
  defaultPayment: 'Paiement par défaut',
  addNewLicenses: "Accorder/Retirer l'autorisation de stationnement",
  addNewProperty: 'Gérer vos places de parking',
  addPropertyButton: 'Ajouter des places de parking',
  licenseTitle: "Gérer les plaques d'immatriculation",
  parkingNotExist: "Vous devez d'abord ajouter des places de parking",
  contact: 'Général',
  FAQ: 'FAQ',
  aboutUs: 'À propos de nous',
  helpSupport: 'Aide & Support',
  termsCondition: 'CGV',
  dataProtection: 'Politique de confidentialité',
  dataProtectionTowShare: 'Politique de confidentialité de TowShare Group GmbH',
  imprint: 'Mentions légales',
  logout: 'Déconnexion',
  editProfile: 'Menu principal',
  done: 'Enregistrer',
  changeProfilePhoto: 'Changer la photo de profil',
  referalCode: 'Code de parrainage',
  redeemDetails: 'Utiliser les détails',
  add: 'Ajouter',
  bankTransfer: 'Virement bancaire',
  newBankDetails: 'Nouveau compte bancaire',
  paypal: 'PayPal',
  newPaypalDetails: 'Nouveaux détails PayPal',
  bankDetails: 'Détails bancaires',
  save: 'Enregistrer',
  beneficaryName: 'Titulaire',
  beneficaryNamePlaceholder: 'Entrez le nom du titulaire',
  bankName: 'Nom de la banque',
  bankNamePlaceholder: 'Entrez le nom de la banque',
  accountNumber: 'Numéro de compte',
  accountNumberPlaceholder: 'Entrez le numéro de compte',
  iban: 'IBAN',
  ibanPlaceholder: "Entrez l'IBAN",
  street: 'Rue',
  streetPlaceholder: 'Entrez la rue',
  streetNumber: 'Numéro de rue',
  streetNumberPlaceholder: 'Entrez le numéro de rue',
  zipCode: 'Code postal',
  zipCodePlaceholder: 'Entrez le code postal',
  city: 'Ville',
  cityPlaceholder: 'Entrez la ville',
  successRedeemSubtitle: 'Vous avez ajouté un compte bancaire',
  successContractSubtitle: 'Votre objet de stationnement a été ajouté',
  rewardsHistory: 'Historique des récompenses',
  history: 'Historique',
  sortBy: 'Trier par',
  dateOfBirth: 'Date de naissance',
  dateOfBirthPlaceholder: 'Entrez votre date de naissance',
  license: "Plaque d'immatriculation",
  govermentId: 'ID / Passeport',
  requestForVerification: 'Demande de vérification',
  verificationRequested: 'Vérification demandée',
  successDriverSubtitle:
    'Nous avons reçu votre demande pour devenir conducteur de stationnement illicite. Le statut de votre demande vous sera communiqué sous 1-2 jours ouvrables.',
  orders: 'Ordres de remorquage',
  badParkedCar: 'Remorquage',
  badParkedCarConfirmationPickup: 'Remorquer le véhicule',
  badParkedCarTicketConfirmationPickup: 'Amende de stationnement',
  licensePlate: "Plaque d'immatriculation",
  licensePlatePlaceholder: "Entrez la plaque d'immatriculation",
  carModelName: 'Nom du modèle de voiture',
  carModelNamePlaceholder: 'Entrez le nom du modèle de voiture',
  carPhoto: 'Photo du véhicule en infraction',
  addPhoto: 'Ajouter une photo',
  addVideo: 'Ajouter une vidéo',
  yourLocation: 'Emplacement du véhicule en infraction',
  findDriver: 'Trouver un remorqueur',
  changeLocation: "Changer l'adresse",
  cancel: 'Annuler',
  cancelSecond: 'Annuler',
  findingTheDriver: 'Recherche de remorqueur...',
  cancelFindDriverAlert: 'Voulez-vous vraiment annuler la mission?',
  cancelOrderAlert: 'Voulez-vous vraiment annuler la commande?',
  confirmOrderAlert:
    'Êtes-vous sûr que toutes les données saisies sont correctes?',
  reward: 'Récompense',
  pickUpLocation: 'Lieu de prise en charge',
  ETA: "Heure d'arrivée estimée",
  towingDriverName: 'Nom du conducteur de remorquage',
  addressNotFound: 'Adresse introuvable. Veuillez réessayer',
  successOrderSubtitle: 'Votre ordre de remorquage a été envoyé avec succès.',
  loginError: 'E-mail ou mot de passe incorrect',
  standardPayment: 'Paiement par défaut',
  addParking: 'Ajouter une propriété',
  unsuccessReportTitle: 'Cher client',
  unsuccessReportSubtitle:
    "Veuillez ajouter votre objet de stationnement pour commencer le rapport de remorquage ou le rapport d'amende.",
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  changePassword: 'Changer le mot de passe',
  confirmChangeSave: 'Les modifications ont été enregistrées',
  orderDetails: 'Détails de la commande',
  carModel: 'Modèle de voiture',
  dateAndTime: 'Date et heure',
  driveInformation: 'Informations sur le conducteur',
  customerInformation: 'Informations utilisateur',
  ticket: 'Ticket',
  orderID: 'Statut de la commande',
  companyExecutive: 'Nom du conducteur de remorquage',
  confirmTicket: 'La compensation a été émise avec succès!',
  confirmTowingStatus: 'Nous remorquons le véhicule en infraction!',
  all: 'Tous',
  new: 'Nouveau',
  accepted: 'Accepté',
  inProgress: 'En cours',
  towedAway: 'Remorqué',
  finished: 'Terminé',
  finish: 'Terminé',
  cancelled: 'Annulé',
  issued: 'Émis',
  userNoReportsYet: 'Aucun rapport créé par vous pour le moment',
  driverNoReportsYet: 'Aucune mission assignée pour le moment',
  propertyAndLicenses: 'Menu principal',
  addProperty: 'Ajouter une place de stationnement / propriété',
  newPropertyDetails: 'Nouvelle place de stationnement',
  addPropertyDetails: 'Ajouter une propriété/place de stationnement',
  addLicenses: 'Ajouter des véhicules autorisés',
  newLicenseDetails: 'Ajouter une nouvelle plaque',
  addLicenseDetails: 'Ajouter une plaque',
  carModelPlaceholder: 'Entrez le modèle de la voiture',
  propertyName: 'Nom de la propriété / place de stationnement (numéro)',
  propertyNamePlaceholder: 'Ajouter un nom',
  address: 'Adresse',
  addressPlaceholder: "Entrez l'adresse",
  signAContract: 'Signer un contrat',
  contract: 'Contrat',
  contractName: 'Contrat de session',
  contractSubHeading:
    "L'application et nos services sont toujours gratuits pour le client.",
  from: 'Date',
  inBetween: 'entre',
  client: 'Client',
  contractor: 'Prestataire',
  contractorAddress: 'TowShare Group GmbH\nSchützengasse 1\n8001 Zürich',
  contractorShortAddress: 'TowShare Group GmbH',
  and: 'et',
  contractSubtitle:
    "<strong>Objet du contrat :</strong> Via l'application FalschParker, les utilisateurs peuvent imposer une indemnité d'inconvénient (amende) aux contrevenants au stationnement et/ou faire remorquer leurs véhicules.",
  contractFirstPointTitle: 'Objet du contrat',
  contractFirstPointContent:
    "Le prestataire prend en charge le traitement et l'envoi des indemnités d'inconvénient en cas de violation des règles de stationnement et procède au remorquage des véhicules en infraction.",
  contractSecondPointTitle: "Indemnité d'inconvénient",
  contractSecondPointContent:
    "Une indemnité d'inconvénient est imposée au contrevenant pour les infractions de stationnement.",
  contractThirdPointTitle: 'Services du prestataire',
  contractThirdPointContent:
    'À la demande du client, le prestataire remorque les véhicules garés illégalement. Cela inclut le remorquage, le transport sécurisé et le stockage approprié des véhicules.',
  contractThirdPointBottomContent:
    "Responsabilité : Le prestataire n'est pas responsable des dommages, sauf en cas de négligence grave ou d'intention.",
  contractFourthPointTitle: 'Mandat',
  contractFourthPointContent:
    "Le mandat est effectué via l'application FalschParker ou, dans des cas particuliers, par téléphone.",
  contractFifthPointTitle: 'Devoir de diligence',
  contractFifthPointContent:
    "Le prestataire s'engage à fournir les services avec soin et compétence, selon les normes techniques actuelles, et est responsable du comportement de ses employés.",
  contractSixthPointTitle: 'Cession de droits',
  contractSixthPointContent:
    "Le client cède tous les droits à l'égard des contrevenants au prestataire, y compris les droits de recouvrement des indemnités d'inconvénient et des ordres de remorquage. En cas de récidive, le prestataire est autorisé à déposer une plainte conformément à l'article 258 CPC.",
  contractSeventhPointTitle:
    'Exactitude des informations concernant le parking',
  contractSeventhPointContent:
    "Le client confirme qu'il existe un contrat de location valide pour le parking ou qu'il en est propriétaire.",
  contractEighthPointTitle: 'Ajout pour les stations-service',
  contractEighthPointContent:
    "Avec l'application FalschParker, les exploitants de stations-service peuvent signaler des cas de vol de carburant et de fuite sans paiement. Le prestataire prend en charge le recouvrement et verse le montant dû pour le carburant au gérant de la station le jour suivant le paiement réussi. Une indemnité d'inconvénient d'au moins 20,00 CHF est imposée au conducteur fautif.",
  contractNinthPointTitle: 'Confidentialité',
  contractNinthPointContent:
    "Les deux parties s'engagent à garder confidentielles toutes les informations obtenues dans le cadre du contrat.",
  contractTenthPointTitle: 'Durée et résiliation',
  contractTenthPointContent:
    'Le contrat prend effet dès la signature et est valable pour une durée indéterminée. Une résiliation est possible à tout moment par écrit.',
  contractEleventhPointTitle: 'Modifications',
  contractEleventhPointContent:
    "Les modifications au contrat nécessitent l'approbation écrite des deux parties.",
  contract12PointTitle: 'Droit applicable et juridiction',
  contract12PointContent:
    'Le contrat est soumis au droit suisse, le for juridique est Zurich.',
  schlieren: 'Schlieren',
  zurich: 'Zurich',
  refCodeNotFound: 'Code de référence introuvable',
  notifications: 'Notifications',
  contractSignature: 'Signature du Contrat',
  inputSignature: 'Saisie de la Signature',
  signatureInfo: 'Pour finaliser le contrat, vous devez signer ici',
  signature: 'Signature',
  termAndConditions: 'J’accepte la collaboration',
  orderNumber: 'Numéro de Commande',
  confirmOrderArrival: 'Êtes-vous sûr d’être arrivé à destination?',
  confirmCarPicked: 'Êtes-vous sûr d’avoir trouvé la bonne voiture?',
  confirmCarParkArrival: 'Êtes-vous sûr d’être arrivé?',
  cancelCarParkArrival: 'Voulez-vous vraiment annuler le contrat?',
  uploadVideoAndPhotos: 'Télécharger Vidéo et Photos',
  uploadVideoAndPhotosInfo:
    'Pour vérification, veuillez télécharger une vidéo de la voiture, suivie de jusqu’à 4 photos.',
  carVideo: 'Vidéo du véhicule mal stationné',
  carkParkLocation: 'Lieu de dépôt du véhicule',
  carFalschOffice: 'Bureau Falsch Parker',
  successDriverOrderSubtitle: 'Vous avez terminé le travail avec succès!',
  registerPlateCheck: 'Contrôle de Plaque d’Immatriculation',
  registerPlateCheckSubtitle:
    'Vérifiez si le véhicule est autorisé à stationner à cette adresse',
  parkedCarRegisterPlateCheck: 'Vérification de Plaque d’Immatriculation',
  formPopupValidation:
    'Êtes-vous sûr que toutes les informations que vous avez saisies sont correctes?',
  registerPlate: 'Plaque d’immatriculation',
  isAllowedToParking: 'est autorisé à stationner',
  isNotAllowedToParking: 'n’est pas autorisé à stationner',
  startTicket: 'Démarrer le Ticket',
  startTowing: 'Démarrer le Remorquage',
  successLicenseSubtitle: 'Plaque d’immatriculation ajoutée avec succès',
  editLicense: 'Modifier la Plaque d’Immatriculation',
  editProperty: 'Modifier le Parking / Propriété',
  changeSave: 'Les modifications ont été enregistrées',
  deleteLicense: 'Supprimer la Plaque d’Immatriculation',
  deleteLicenceConfirm:
    'Êtes-vous sûr de vouloir supprimer la plaque d’immatriculation enregistrée?',
  switchToDriverProfile: 'Passer au Profil de Conducteur',
  superAdmin: 'Super Administrateur',
  user: 'Utilisateur',
  driver: 'Conducteur',
  customerName: 'Utilisateur',
  customerNumber: 'Numéro de Client',
  orderPlacement: 'Heure de la Commande',
  ticketOrderPlacement: 'Heure de l’Infraction',
  orderAcceptence: 'Acceptation de la Commande',
  arrivalTime: 'Heure d’Arrivée',
  timeOfPickup: 'Heure de la Prise en Charge',
  timeOfExecution: 'Moment de la Remise',
  verifyCarOwner: 'Vérifier le Propriétaire du Véhicule',
  fullName: 'Nom Complet',
  fullNamePlaceholder: 'Entrez le Nom Complet',
  contactDetails: 'Détails de Contact',
  emailAddress: 'E-mail',
  fullAddress: 'Adresse Complète',
  driverId: 'Permis de Conduire & ID (Avant)',
  carRegistration: 'Carte Grise',
  recent: 'Récent',
  older: 'Ancien',
  licenseFront: 'Permis de Conduire & ID (Avant)',
  licenseBack: 'Permis de Conduire & ID (Arrière)',
  govermentIdFront: 'Carte Grise',
  govermentIdBack: 'Passeport',
  helpAndSupport: 'Aide et Support',
  falschParkerHelp: 'Aide Falsch Parker',
  helpContent:
    'Vous pouvez joindre le support 24h/24 par téléphone ou WhatsApp. Nous répondons aux questions sur le fonctionnement de l’application ou sur des actions spécifiques directement et sans détour.',
  allowedCarPopup:
    'Ce véhicule est autorisé à stationner et ne peut pas être remorqué.',
  back: 'Retour',
  call: 'Appeler',
  message: 'Message',
  confirm: 'confirmer',
  confirmShort: 'OUI',
  confirmNext: 'Suivant',
  driverName: 'Nom du Conducteur',
  delete: 'supprimer',
  retake: 'reprendre',
  language: 'Langue',
  defaultLanguage: 'Langue par Défaut',
  de: 'Allemand',
  en: 'Anglais',
  it: 'Italien',
  fr: 'Français',
  oldPasswordPlaceholder: 'Entrez l’ancien mot de passe',
  newPasswordPlaceholder: 'Entrez un nouveau mot de passe',
  destinationArrived: 'Arrivé à Destination',
  userName: 'Nom d’utilisateur',
  downloadReceipt: 'Télécharger le Reçu',
  imptint: 'Mentions Légales',
  imptintTitle: 'Mentions Légales',
  driverAcceptError: 'Vous ne pouvez pas accepter plus d’un rapport à la fois',
  referAndPromote: 'Ajouter',
  referToPromote: 'Ajoutez un Employé',
  orderStatus: 'Statut de la Commande',
  driverAcceptConfirm: 'Êtes-vous sûr de vouloir accepter la commande?',
  driverDectineConfirm: 'Voulez-vous vraiment refuser le rapport?',
  backendErrorPartOne:
    'Nous avons rencontré une erreur. Veuillez contacter le support de ',
  backendErrorPartTwo: '',
  noNotification: 'Aucune notification disponible pour l’instant',
  closeReportContent: 'Voulez-vous vraiment annuler le contrat?',
  executeOrder: 'Démarrer la Restitution du Véhicule',
  pleaseAddSignature: 'Veuillez ajouter une signature',
  next: 'Suivant',
  accept: 'Accepter',
  decline: 'Annuler',
  arrived: 'Arrivé',
  successCarOwenrContractSubtitle:
    'Merci pour la finalisation du rapport de remorquage',
  minute: 'Minute',
  minutes: 'Minutes',
  turnOnGPS: 'Activer le GPS',
  gpsPopupContent:
    'Cher utilisateur. Veuillez activer le GPS de votre appareil. Sinon, vous ne pourrez pas utiliser l’application',
  gpsErrorPopupContent:
    'Cher utilisateur. Veuillez autoriser votre navigateur à utiliser les services de localisation. Vous pouvez le faire dans les paramètres du navigateur',
  404: 'Oups! La page à laquelle vous tentiez d’accéder est introuvable',
  searchPlaceholder: 'Rechercher une plaque d’immatriculation...',
  resetPasswordTitle: 'E-mail de Réinitialisation de Mot de Passe',
  resetPassword: 'Envoyer le Mot de Passe',
  resetPasswordError: 'L’e-mail fourni n’est associé à aucun compte',
  resetPasswordSuccess:
    'Vous devriez recevoir un e-mail avec les instructions pour réinitialiser le mot de passe',
  userExist: 'Cet e-mail est utilisé pour un autre compte. Veuillez changer',
  yes: 'Oui',
  no: 'Non',
  pinWrongParkedLocation: 'Emplacement du véhicule mal garé',
  pinTowingTruckLocation: 'Emplacement du camion de remorquage',
  pinParkingSpaceLocation: 'Adresse du parking',
  googleMapsPopupContent:
    'Voulez-vous utiliser Google Maps pour la navigation ?',
  screenAlwaysOn: 'Veuillez garder l’écran toujours allumé',
  tooBigFile:
    'Le fichier vidéo est trop volumineux. Veuillez créer une vidéo plus courte.',
  uploadVideoAndPhotos360: 'Vidéos et photos 360 degrés',
  uploadVideoAndPhotos360Subtitle:
    'Pour vérifier, téléchargez une vidéo de la voiture, suivie de 3 photos',
  twentyCashback: '20 CHF REMBOURSEMENT',
  fiftyCashback: '50 CHF REMBOURSEMENT',
  reportNumber: 'Numéro de commande',
  tenChf: '10 CHF CRÉDIT',
  referAndGet: 'RECOMMANDER + ',
  towingActiveHistoryTitle: 'Nouvelles commandes',
  towingDeactiveHistoryTitle: 'Commandes de remorquage',
  ticketHistoryTitle: 'Contraventions de stationnement',
  allHistoryTitle: 'Historique de tous les rapports',
  please: 'Veuillez',
  mark: 'confirmer',
  rentalAgreementPhotos: 'Photos du contrat de location',
  addRentalProperty: 'Ajouter un bien locatif',
  newRentalPropertyDetails: 'Nouvelle propriété (Détails)',
  addRentalPropertyDetails: 'Ajouter des détails sur la propriété locative',
  uploadPhotos: 'Télécharger des photos',
  uploadPhotosInfo:
    'Pour vérification, téléchargez des images du contrat de location',
  invalidZipCodePopup:
    "Le service de remorquage n'est pas disponible dans votre région. Nous travaillons pour le rendre disponible bientôt.",
  paymentMethod: 'Méthode de paiement',
  invoice: 'Facture',
  paymentOnTheSpotCash: 'Paiement sur place (Espèces)',
  paymentOnTheSpotCard: 'Paiement sur place (Carte de crédit)',
  freeParking: 'Parking de dépose',
  inProgressButton: 'Commande en cours',
  inProgressSubtitle: 'Vérifiez le rapport en cours',
  policeParkingAddress: 'Adresse de la place de stationnement',
  acceptedReportError: 'Un autre conducteur a déjà accepté ce rapport',
  clientName: "Nom de l'entreprise",
  selectParkingArea: 'Parkings clients à proximité',
  autoGPSOff:
    'Cher utilisateur, veuillez activer le GPS sur votre appareil pour une localisation automatique.',
  paymentStatus: 'État du paiement',
  cashbackStatus: 'Statut du remboursement',
  open: 'Ouvert',
  paid: 'Payé',
  notPaid: 'Non payé',
  paidOnTheSpot: 'Payé sur place',
  paidOnSpotButton: 'Client payé sur place',
  pending: 'En attente',
  clients: 'Clients',
  clientNumber: 'Numéro de client',
  contactNumber: 'Numéro de contact',
  clientFullName: 'Nom du client',
  cashbackOn: 'Remboursement activé',
  cashbackOff: 'Remboursement désactivé',
  clientsSearchPlaceholder: 'Rechercher des clients ...',
  wrongAddress:
    'Veuillez saisir une adresse correcte. Votre adresse n’a pas été trouvée sur Google Maps',
  clientReports: 'Commandes terminées pour les clients',
  clientReportsTitle: 'Commandes terminées du client',
  clientReportsSearchPlaceholder: 'Rechercher...',
  downloadContract: 'Télécharger le contrat',
  cashback: 'Remboursement',
  claimed: 'Réclamé',
  bankAccount: 'Compte bancaire',
  newBank: 'Ajouter un nouveau compte bancaire',
  downloadAppPopup: 'Téléchargez l’application FalschParker',
  tap: 'Cliquez pour',
  tapInstruction: 'ajouter à l’écran d’accueil',
  sign: 'Signer',
  bill: 'Facture',
  gasStationButtonTitle: 'Évasion de carburant',
  gasStationButtonSubtitle: 'Carburant sans paiement',
  photo: 'Photo du véhicule',
  video: 'Vidéo (max. 30 sec)',
  controlPlate: 'Plaque de contrôle',
  controlPlatePlaceholder: 'Entrez la plaque de contrôle',
  billPhoto: 'Photo du reçu de carburant',
  gasStationAddressTitle: 'Emplacement de la station-service',
  price: 'Prix',
  priceGasStation: 'Montant de carburant',
  billTime: 'Heure de remplissage',
  cancelledTime: 'Heure annulée',
  gasStationSuccessReport:
    'L’infraction de carburant a été signalée avec succès',
  bankAccountName: 'Nom du titulaire',
  bankAccountNameePlaceholder: 'Saisir le nom du titulaire',
  gasStationOn: 'Évasion de carburant activée',
  gasStationOff: 'Évasion de carburant désactivée',
  confirmReportDelete: 'Êtes-vous sûr de vouloir supprimer la commande ?',
  deleteReport: 'Supprimer la commande',
  gasStationSelect: 'Sélectionner la station-service',
  activateParkingTitle:
    'Enregistrez votre plaque de contrôle pour être identifié en tant que visiteur',
  activateParkingPlaceholder: 'Entrez la plaque de contrôle',
  confirmActivateParking: 'Enregistrer',
  confirmActivatedParking: 'Plaque de contrôle enregistrée pour 24h',
  cancelSelectParking: 'Annuler',
  ticketId: 'ID de contravention',
  ticketIdPlaceholder: 'Scanner le code QR',
  notes: 'Note',
  notesPlaceholder: 'Optionnel',
  userOrderAddress: 'Sélectionner l’emplacement',
  parkingNotes: 'Décrivez l’emplacement exact de votre place de stationnement',
  uploadParkingPhotos: 'Prenez une photo de votre place de stationnement',
  parkingTitle: 'Nom de l’emplacement du parking',
  reportType: 'Type de rapport',
  towingTickets: 'Commandes / Contraventions',
  priceBill: 'Montant de la facture de carburant',
  priceBillPlaceholder: 'Saisir le montant de la facture de carburant',
  priceCarOwner: 'Prix du remorquage',
  priceCarOwnerPlaceholder: 'Saisir le prix du remorquage',
  plateValidation: 'Lettres et chiffres uniquement',
  selectUserType: 'Veuillez sélectionner le type d’utilisateur :',
  companyClient: 'Client entreprise',
  userClient: 'Client particulier',
  pleaseSelectParking: 'Veuillez sélectionner une zone de parking',
  pleaseChangeAddress:
    'Veuillez saisir une autre adresse et sélectionner une zone de parking',
  appVersion: 'Version de l’application',
  confrimPayOnSpot: 'Confirmez-vous le paiement sur place ?',
  selectTheLocation: 'Sélectionner l’emplacement',
  qrScanner: 'Scanner QR',
  fuelFraud: 'Évasion de carburant',
  ticketSection: 'Contraventions de stationnement',
  pricePlaceholder: 'Saisir le prix',
  cashbackPlaceholder: 'Saisir le remboursement',
  gasStationSection: 'Évasion de carburant',
  towingSection: 'Remorquage',
  accidentalEvasion: 'Était dans le magasin',
  intensionalEvasion: 'N’était pas dans le magasin',
  accidentalEvasionCashback: 'Était dans le magasin avec remboursement',
  intensionalEvasionCashback: 'N’était pas dans le magasin avec remboursement',
  emptyRun: 'Course à vide',
  cameraAlert:
    'La caméra est bloquée ou inaccessible. Veuillez autoriser l’accès à la caméra dans vos paramètres de navigateur et actualiser.',
  loadingMediaPopup:
    'Téléchargement des fichiers en cours, veuillez ne pas fermer l’application',
  objectionRaiseEmailPlaceholder: 'Votre e-mail',
  notePlaceholder: 'Description de l’objection',
  driverCityPlaceholder: 'Entrer la ville',
  qrCodeId: 'ID QR-contravention',
  cancelButton: 'Annuler',
  sharingError: 'Votre système ne prend pas en charge le partage de ce fichier',
  startScanner: 'Démarrer le scanner',
  downloadQrCode: 'Télécharger le code QR',
  generateQrCode: 'Afficher le code QR',
  pleaseAddPrice: 'Veuillez ajouter un prix',
  emptyRunCashback: 'Remboursement pour course à vide',
};
