import { Layout } from 'components/Layout/Layout';
import { sortByOptions } from 'data/sortOptions';
import { useAccountType } from 'hooks/useAccountType';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SuperAdminReportsHistory } from './SuperAdminReportsHistory/SuperAdminReportsHistory';
import { getStatusIndex } from 'utils/getStatusIndex';
import { DriverReportsHistory } from './DriverReportsHistory/DriverReportsHistory';
import { UserReportsHistory } from './UserReportsHistory/UserReportsHistory';
import { getPaymentStatusIndex } from 'utils/getPaymentStatusIndex';
import { HistoryTopBar } from './HistoryTopBar/HistoryTopBar';
import { HistoryHeading } from './HistoryHeading/HistoryHeading';
import { appContext } from 'views/App';

const StyledBottomContainer = styled.div`
  height: 46px;
`;

export const History = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { type } = useParams();
  const [sortBy, setSortBy] = useState<string>(sortByOptions[0]);
  const [statusIndex, setStatusIndex] = useState<string | undefined>(undefined);
  const [isReportsListEnd, setIsReportsListEnd] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const { isThisAccountType: isDriverAccount } = useAccountType('driver');
  const { isThisAccountType: isSuperAdminAccount } =
    useAccountType('superAdmin');
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const handleStatusChange = (status: string) => {
    const isGasStationReports = type === 'station';
    if (isGasStationReports) {
      const newStatusIndex = getPaymentStatusIndex(status);
      setStatusIndex(newStatusIndex);
    } else {
      const newStatusIndex = getStatusIndex(status);
      setStatusIndex(newStatusIndex);
    }
  };
  const order = sortBy === 'recent' ? 'DESC' : 'ASC';

  return (
    <Layout
      userBarContent={
        <HistoryTopBar
          handleStatusChange={(status) => handleStatusChange(status)}
          handleSearchValueChange={(searchValue) => setSearchValue(searchValue)}
        />
      }
      isMenuBar
      mode="top"
      handleContentEnd={(isContentEnd) => setIsReportsListEnd(isContentEnd)}
    >
      <HistoryHeading handleSortBy={(option) => setSortBy(option)} />
      {userData && (
        <div>
          {isDriverAccount && (
            <DriverReportsHistory
              order={order}
              status={statusIndex}
              searchValue={searchValue}
              isActiveStatuses={type === 'active'}
              isDeactiveStatuses={type === 'deactive'}
              isTickets={type === 'tickets'}
              isReportsListEnd={isReportsListEnd}
            />
          )}
          {isSuperAdminAccount && (
            <SuperAdminReportsHistory
              order={order}
              status={statusIndex}
              searchValue={searchValue}
              isActiveStatuses={type === 'active'}
              isDeactiveStatuses={type === 'deactive'}
              isTickets={type === 'tickets'}
              isGasStation={type === 'station'}
              isReportsListEnd={isReportsListEnd}
            />
          )}
          {isUserAccount && (
            <UserReportsHistory
              order={order}
              status={statusIndex}
              searchValue={searchValue}
              isActiveStatuses={type === 'active'}
              isDeactiveStatuses={type === 'deactive'}
              isTickets={type === 'tickets'}
              isGasStation={type === 'station'}
              isReportsListEnd={isReportsListEnd}
            />
          )}
        </div>
      )}
      <StyledBottomContainer />
    </Layout>
  );
};
